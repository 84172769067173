@font-face {
  font-family: 'MorganStanley';
  src: url('./MorganStanleySansBETA-Bold.otf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'MorganStanley';
  src: url('./MorganStanleySansBETA-Medium.otf') format('truetype');
  font-weight: medium;
  font-style: normal;
}

@font-face {
  font-family: 'MorganStanley';
  src: url('./MorganStanleySansBETA-Regular.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
